import { IEnvironmentBase } from '@aex/ngx-toolbox';

export interface IEnvironment extends IEnvironmentBase {
	paymentServiceUrl?: string
	purchaseOrderServiceUrl?: string
	serverUrl: string
	nmsServerUrl?: string
	ipPoolServerUrl?: string
	ispServerUrl?: string
	searchServiceUrl?: string
	solarServiceUrl?: string
	billingServiceUrl?: string
	radiusServiceUrl?: string
	supportServiceUrl?: string
	wifiServiceUrl?: string
	workOrderEventUrl?: string,
	eventsUrl?: string,
	speedTestUrl: string,
	assetsServerUrl?: string;
	kmlServerUrl?: string;
	knoxUrl?:string;
	knoxEnabled?:boolean;
	override?: Partial<IEnvironment>
	operator?: string,
	token?: string
}

const URL_PLACEHOLDER = 'to-be-replaced';

export function env(inbound?: Partial<IEnvironment>): IEnvironment {
	return {
		name: 'dev',
		version: '2.0',
		useServiceWorker: true,
		production: false,
		mocksEnabled: false,
		paymentServiceUrl: URL_PLACEHOLDER,
		purchaseOrderServiceUrl: URL_PLACEHOLDER,
		serverUrl: URL_PLACEHOLDER,
		nmsServerUrl: URL_PLACEHOLDER,
		ipPoolServerUrl: URL_PLACEHOLDER,
		ispServerUrl: URL_PLACEHOLDER,
		searchServiceUrl: URL_PLACEHOLDER,
		solarServiceUrl: URL_PLACEHOLDER,
		supportServiceUrl: URL_PLACEHOLDER,
		radiusServiceUrl: URL_PLACEHOLDER,
		billingServiceUrl: URL_PLACEHOLDER,
		workOrderEventUrl: URL_PLACEHOLDER,
		eventsUrl: URL_PLACEHOLDER,
		speedTestUrl: URL_PLACEHOLDER,
		...(inbound || {}),
	};
}
